.create-specialist-modal {
  .steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    background-color: #fafafa;
    text-align: center;
    padding-top: 20px;
  }

  .steps-action {
    margin-top: 24px;
  }
}
