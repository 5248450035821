.button-edit {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-left: 200px;
}

.description-collapse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #077907;
}

.description-container {
  display: flex;
  flex-direction: row;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.modal-edit {
  &-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-button {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
}

.property-card {
  border-radius: 12px;
  background: #ffff;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05); */

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    color: #776e6f;
    margin-bottom: 16px;
  }

  .item {
    display: flex;
    flex-direction: column;

    &__label {
      color: #423d3e;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 600;
    }

    &__value {
      color: #423d3e;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 500;
    }

    &:before {
      content: ' ';
      height: 1px;
      width: 100%;
      background: #d9d9d9;
      margin: 5px 0 20px 0;
    }
  }
}
