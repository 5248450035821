$font: 'Montserrat',
sans-serif;
$primary: #333;
$secondary: #999;
$background-color: #ffffff;

//Breakpoints
$desktop: '1367px';
$laptop: '1220px';
$medium: '1025px';
$tablet: '770px';
$mobile: '451px';

//colors