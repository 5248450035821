.user-detail {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span {
      flex: 1;
      font-weight: bold;
      text-align: center;
    }
  }

  &-image {
    display: flex;
    margin: 0 0 20px 0;
    justify-content: center;
  }

  &-general {
    margin: 0px 16px 10px 16px;
    display: flex;
    flex-direction: row;
    gap: 8rem;
  }

  .description {
    padding: 0 16px;

    h2 {
      padding-bottom: 10px;
    }
  }

  p span {
    font-weight: bold;
  }

  .ant {
    &-col {
      margin: 2px 0;
    }
    &-collapse { 
      background-color: #fff;

      &-header {
        font-weight: bold;
      }
    }
  }

}
