.modal-rol {
    &-company {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    &-button {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
        margin-right: 2.5rem;
    }
}