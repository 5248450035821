.ant-select-selector {
    border-radius: 5px !important;
}

.ant-input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.ant-input-search-button {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.btn-default {
    background: #ebebeb;
    border-radius: 5px;
    border: 0px;
    color: #000;
    font-size: 14px;

    &:hover {
        background: #d3d4d3;
        color: #000;
    }
}

.ant-btn-primary {
    background: #ff5a70;
    border: 0px;
    border-radius: 5px;

    &:hover {
        background: #d13046;
    }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ff8388;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ff8388;
    box-shadow: none;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
    border-color: #ff8388;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #d82067;
}

.ant-tabs-tab:hover {
    color: #d82067 !important;
}

.ant-tabs-ink-bar {
    background: #d82067 !important;
}

.ant-tag {
    border-radius: 10px;
}