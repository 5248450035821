//libraries

@import '~antd/dist/antd.css';

//base
@import "base/reset";
@import "base/variables";
@import "base/animations";
@import "base/form";
@import "base/mixins";
@import "base/elements";

//modules
@import "modules/all";

@import "theme/colors";

.btn-login {
  display: block;
  background: linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%);
  filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
  border-radius: 100px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  padding: 0 3em;
  outline: none;
  border: none;
}