.user {
  &-header {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-weight: bold;
  }

  .ant-table {
    border: 1px solid #e5e3e3;
    border-radius: 10px;
  }

  .ant-table-title {
    border-bottom: 1px solid #e5e3e3;
  }

  .ant-table-thead > tr > th{
    padding: 10px 16px;
  }
}