.ticket-modal {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span {
      flex: 1;
      font-weight: bold;
      text-align: center;
    }
  }

  .description {
    padding: 0 16px;

    h2 {
      padding-bottom: 10px;
    }
  }

  p span {
    font-weight: bold;
  }

  .ant {
    &-col {
      margin: 2px 0;
    }
    &-divider {
      margin: 20px 0 0 0;
      &-inner-text {
        font-weight: bold;
      }
    }
    &-collapse {
      background-color: #fff;
    }

    &-modal-title {
      font-weight: bold;
    }
  }
  img {
    height: 100px;
    margin: 0 10px;
  }
}

.ant-timeline-item{
  padding-bottom: 0;
}

.contract-detail{
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      flex: 1;
      font-weight: bold;
      text-align: center;
    }
  }
}

.New_Valor{
  margin-right: 2rem;
  justify-content: center;
  width: 15rem;
}
