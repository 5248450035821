.profile-modal {
  p span,
  .ant-modal-title {
    font-weight: bold;
  }

  &-properties {
    margin: 20px 0;

    p {
      font-weight: bold;
    }
  }
}
