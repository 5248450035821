.signup-form {
  margin: 0 1em;

  .ant-input-affix-wrapper {
    border-radius: 20px;
    height: 40px;
  }

  &_welcome {
    font-size: 25px;
    margin-bottom: 1em;

    strong {
      color: #65137d;
    }

    h1 {
      margin-top: 8px;
      margin-bottom: 0.5em;
      font-size: 36px;
      color: #888888;
      font-weight: bold;
    }
  }

  &_input__icon {
    @include flex-center;
    margin-left: -1em;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
    border-radius: 100px;
    width: fit-content;
    padding: 1em 2em;
    color: #b3b3b3;
    margin-bottom: 1em;

    &:focus-within {
      border: 1px solid #d82067;
      color: #d82067;

      input {
        color: #d82067;

        &::placeholder {
          color: #d82067;
        }
      }
    }

    input {
      border: none;
      color: #b3b3b3;
    }

    svg {
      margin-right: 0.5em;
    }
  }

  &__forgot_pass {
    display: inline-block;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #65137d;
    margin: 1em 0;

    &:hover {
      text-decoration: underline;
    }
  }

  button[type="submit"] {
    display: block;
    background: linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%);
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;
  }
}
