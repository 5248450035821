.cardItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: rgb(128, 28, 128);
    color: white;
    border-radius: 1rem;
}

.value {
    margin-top: 0px;
    font-weight: 700;
    font-size: 15px;
}
.row {
    display: flex;
    justify-content: center;
}

/* @media (max-width: 576px) {
    .card {
        width: 150px;
        flex-direction: row;
    }
}

@media (max-width: 768px) {
    .card {
        width: 250px;
        
    }
    .gutter-row{
        display: flex;
        flex-direction: row;
    }
}
 */