.property-detail {
  &-header {
    align-items: center;
    margin: 0 auto;
    background: #ffff;
    border-radius: 12px;
    margin-bottom: 18px;
    padding: 28px 20px;
    box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
    .header-top {
      height: 6px;
      width: 100%;
      margin-bottom: 0;
      background-color: #ff5a70;
    }
    span {
      flex: 1;
      font-weight: 500;
      text-align: center;
      font-size: 18px;
    }
  }
  &-header-bar {
    height: 6px;
    width: 100%;
    margin-bottom: 0;
    background-color: #ff5a70;
  }

  .description {
    h2 {
      padding-bottom: 10px;
    }
  }

  p span {
    font-weight: bold;
  }

  .ant {
    &-col {
      margin: 2px 0;
    }
  }

  img {
    height: 100px;
    margin: 0 10px;
  }

  .test {
    width: 50%;
  }

  .ant-table-cell {
    font-size: 13px;
  }
}

.gutter-row {
  margin-bottom: 20rem;
}
