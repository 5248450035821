.header-profile {
  padding: 0 1.8em;
  padding-right: 0;
  position: relative;
  overflow: hidden;

  &--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .background-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
  }

  .font-color-darken {
    color: #c91d1d;

    &--admin {
      color: #d82067;
      font-weight: 500;
      
    }
    
    
  }

  &--profile {
    background: radial-gradient(top right, #00515346 0%, transparent 61%);
    margin-bottom: -5rem;

    .background-img {
      opacity: 0.4;
      z-index: 0;
    }

    .sub-header {
      opacity: 0;
    }
  }

  &--admin-dashboard {
    background: radial-gradient(top right, #0051532c 0%, transparent 61%);

    .background-img {
      opacity: 0.6;
      background-size: 70%;
      /*     background-image: linear-gradient(to top, rgba(246, 246, 246, 0.98) 7%, transparent 80%),
        url("assets/images/background-welcome-logo.png"); */
      background-position: -10% 5%;
    }
  }
  .user-data {
    display: flex;
    flex: 1;
  }

  .sub-header {
    display: flex;
    flex-grow: 1;
    z-index: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0em 2em 0em 0;
    margin: 2em 0 1em 0;
  }

  .icon-name {
    background-color: #dddddd;
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    margin-right: 10px;
    font-size: 1.5em;
    color: #666;
  }

  .icon-name__acron {
    font-weight: bold;
    align-self: center;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #7d8c9b;

    &__greet {
      color: #d82067;
    }

    &__name {
      margin: 0;
      font-size: 1.5em;
    }

    &__rol {
      margin: 0;
      margin-top: 0.2em;
      font-size: 0.9em;
    }

    &__dash {
      margin: 0;
      margin-top: 0em;
      font-size: 1.3em;
      color: #7d8c9b;
      
    }
  }

  .admin-dashboard--btn {
    background: rgba(251, 251, 251, 0.46);
    z-index: 1;
    border-radius: 50px;
    margin-right: 2em;
    padding: 0.5em 1em;

    button {
      &:first-child {
        margin-right: 1em;
      }

      cursor: pointer;
      color: #888888;
      background: #ffffff;
      border: 0.5px solid #888888;
      border-radius: 50px;
      padding: 0.5em 1em;

      svg {
        font-size: x-large;
        margin-right: 0.5em;
      }
    }
  }
}

strong::first-letter {
  text-transform: uppercase;
  color: #8f2100;
}

@media (max-width: 800px) {
  .header-profile .admin-dashboard--btn {
    margin-right: 0;
    width: 18em;
    text-align: center;

    button {
      &:first-child {
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
  }
}

@media (max-width: 680px) {
  .header-profile .sub-header {
    padding: 2em 0;
    margin-left: -35px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
  }

  .header-profile .admin-dashboard--btn button svg {
    margin-right: 0;
  }
}
