.private {
  .logo {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 36px;

    img {
      width: 140px;
      margin-bottom: 12px;
    }
  }

  .site-layout-sub-header-background {
    background: #fff;
  }

  .site-layout-background {
    background: #fff;
  }

  .pricing .ant-statistic-content-value {
    font-size: 20px;
  }

  .pricing .ant-statistic-content-suffix {
    font-size: 15px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }

  .ant-input {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-radius: 5px !important;
  }
  .ant-input-number{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-radius: 5px !important; 
  }

  .ant-input-search-button {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .btn-default {
    background: #ebebeb;
    border-radius: 5px;
    border: 0px;
    color: #000;
    font-size: 14px;

    &:hover {
      background: #d3d4d3;
    }
  }

  .ant-btn-primary {
    background: #ff5a70;
    border: 0px;
    border-radius: 5px;

    &:hover {
      background: #d13046;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ff8388;
  }

  .ant-picker {
    border-radius: 5px;
  }

  .ant-picker:hover,
  .ant-picker:focus,
  .ant-picker-focused {
    border-color: #ff8388;
    box-shadow: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #ff8388;
    box-shadow: none;
  }

  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #ff8388;
  }
}
