.menu {
    display: flex;
    justify-content: space-between;
    padding-right: 2em;
    padding-left: 3em;
    
    &__nav {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #888888;
      width: 70%;
      justify-content: center;
      li {
        display: inline-block;
        justify-content: space-around;
        color: #7d8c9b;
        margin-right: 2em;
      }
      a:hover {
        color: #d82067;
      }
      a:focus {
        color: #d82067;
      }
    }
  }

  .title__dash {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 30px;
    color: #888888
    ;
  }