.profile-form {
  margin: 0 1em;

  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker {
    border-radius: 20px !important;
    height: 40px !important;
  }
  .ant-input {
    border-radius: 15px !important;
  }
}
