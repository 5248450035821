.renter-add-modal {
    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
  
      span {
        flex: 1;
        font-weight: bold;
        text-align: center;
      }
    }
  
    .description {
      padding: 0 16px;
  
      h2 {
        padding-bottom: 10px;
      }
    }
  
    p span {
      font-weight: bold;
    }
  
    .ant {
      &-col {
        margin: 2px 0;
      }
      &-divider {
        margin: 20px 0 0 0;
        &-inner-text {
          font-weight: bold;
        }
      }
      &-collapse {
        background-color: #fff;
      }
  
      &-modal-title {
        font-weight: bold;
      }
    }
    img {
      height: 100px;
      margin: 0 10px;
    }
  }
  