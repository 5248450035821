.sider {

  /*   .menu-item {
    background: #001529 !important;
  } */

  .ant-menu-vertical>.ant-menu-item,
  .ant-menu-vertical-left>.ant-menu-item,
  .ant-menu-vertical-right>.ant-menu-item,
  .ant-menu-inline>.ant-menu-item,
  .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 52px;
    display: flex;
    align-items: center;
  }

  .menu-item-selected {
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);

    .anticon+span {
      font-weight: 600 !important;
    }


    .anticon {
      box-shadow: none !important;
      background-color: rgb(255, 222, 226) !important;
      color: rgb(216, 32, 103);
    }
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: rgb(216, 32, 103);
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    background-color: #ffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
    padding: 8px;
    border-radius: 8px;
    font-size: 18px;
  }

  .ant-layout-sider {
    flex: 0 0 250px;
    max-width: 250px;
    min-width: 250px;
    width: 250px;
  }

  .ant-layout-sider {
    background: red !important;
  }

  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
  .ant-menu-dark .ant-menu-item-selected .anticon+span {
    font-weight: 600;
  }

  .ant-menu-item .ant-menu-item-icon+span,
  .ant-menu-submenu-title .ant-menu-item-icon+span,
  .ant-menu-item .anticon+span,
  .ant-menu-submenu-title .anticon+span {
    font-weight: 500;
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #00152900;
  }
}