.shopping-detail {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #d82067;
    }

    .ant-tabs-tab:hover {
        color: #d82067 !important;
    }

    .ant-tabs-ink-bar {
        background: #d82067 !important;
    }
}