.recover-password {
  padding: 10px 50px 10px 50px;

  .ant-input-affix-wrapper {
    border-radius: 25px;
    height: 45px;
  }


  button[type="submit"] {
    display: block;
    background: linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%);
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;
  }

  .logo {
    width: 110px;
    margin: 0 auto;
    /* background: red; */
    display: block;
  }

  &_welcome {
    font-size: 25px;
    margin-bottom: 1em;

    strong {
      color: #65137d;
    }

    h1 {
      margin: 0 auto;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 1.3em;
      font-size: 28px;
      color: #333;
      font-weight: bold;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 25px;
    height: 45px;
  }

  &_input__icon {
    @include flex-center;
    margin-left: -1em;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
    border-radius: 100px;
    width: fit-content;
    padding: 1em 2em;
    color: #b3b3b3;
    margin-bottom: 1em;

    &:focus-within {
      border: 1px solid #d82067;
      color: #d82067;

      input {
        color: #d82067;

        &::placeholder {
          color: #d82067;
        }
      }
    }

    input {
      border: none;
      color: #b3b3b3;
    }

    svg {
      margin-right: 0.5em;
    }
  }

  &__forgot_pass {
    display: inline-block;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #65137d;
    margin: 1em 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &__check_terms {
    @include flex-center;
    font-size: x-small;
    max-width: 260px;
    margin-bottom: 2em;

    label {
      margin-left: 1em;
    }

    a {
      font-style: italic;
      font-weight: bold;
      color: #65137d;
    }
  }

  button[type="submit"] {
    display: block;
    background: linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%);
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;
  }

  form > div {
    margin-bottom: 1.3em;
  }
  .btn-login {
    width: 100%;
  }
}
.slide-left {
  width: 100%;
  margin-left: 100px;
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
* Generated by Animista on 2021-10-15 1:1:51
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-left
* ----------------------------------------
*/
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
