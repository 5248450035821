.conciliation {
  .statics {
    .ant-col {
      padding: 6px 10px;
      .title {
        color: #848484;
      }
    }
  }
}
