//Any mixins (style snippets) that will be used over and over
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin focus-anim-shadow {
  &:focus {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
  }
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */

@mixin fade-in() {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}